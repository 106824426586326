import styled from '@emotion/styled'

export const DashboardWrapper = styled.div`
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(100% - 55px); // TODO: To be removed when <AdditionalFilters> is reenabled
    // height: calc(100% - 55px - 25px); /* Removing header's height and filters subheader to avoid the internal scroll */
    overflow: hidden;

    & iframe {
        border: none !important;
        overflow: hidden;
    }
`

export const PowerBIiFrame = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`
